import { APIUrlList } from '../js/api_url';
import { _sendResponse } from '../js/common-function';
import { apiCall } from '../js/common-function';
import axios from 'axios';

export const addEditProduct = async (data) => {
    try {
        let response = await apiCall(APIUrlList.ADDEDITPRODUCT, data, 'POST', true);
        if (response.data.status === true){
            _sendResponse('success', response.data.message);
        } else {
            _sendResponse('error', response.data.message);
        }
        return response.data.data;
    } catch (error){
        _sendResponse('error', error);
    }
};

export const productList = async (id) => {
    let objToSend = {
        fab_id: id
    };
    try {
        let response = await apiCall(APIUrlList.PRODUCTLIST, objToSend,'POST', true);
        return response.data.data;
    } catch (error){
        _sendResponse('error', error);
    }
};

export const handleNextProductPage = async (url, id) => {
    try {
        let objToSend = {
            fab_id: id
        };
        const headers = {Authorization: 'Bearer '+localStorage.getItem('user-token')};
        let response = await axios.post(url, objToSend, {headers: headers});
        return response.data.data;
    } catch (error){
        _sendResponse('error', error);
    }
};

export const deleteProduct = async (id) => {
    try {
        const response = await apiCall( APIUrlList.DELETEPRODUCT+`/${id}`, null, 'GET', true );
        if (response.data.status === true){
            _sendResponse('success', response.data.message);
        } else {
            _sendResponse('error', response.data.message);
        }
        return response.data.data;
    } catch (error){
        _sendResponse('error', error);
    }
};

export const updateFabSetting = async (data) => {
    try {
        const response = await apiCall( APIUrlList.UPDATEFABSETTING, data, 'POST', true );
        if (response.data.status === true){
            _sendResponse('success', response.data.message);
        } else {
            _sendResponse('error', response.data.message);
        }
        return response.data.data;
    } catch (error){
        _sendResponse('error', error);
    }
};

export const getStorePaymentsList = async (page) => {
    try {
        let response = await apiCall(APIUrlList.STOREPAYMENTS+`?page=${page}`, {}, 'GET', true);
        return response.data.data;
    } catch (error) {
        _sendResponse('error', error);
    }
};

export const getInteractionPaymentsList = async (page) => {
    try {

        let response = await apiCall(APIUrlList.INTERACTIONPAYMENTS+`?page=${page}`, {}, 'GET', true);
        return response.data.data;
    } catch (error) {
        _sendResponse('error', error);
    }
};

export const getGabPaymentsList = async (page) => {
    try {

        let response = await apiCall(APIUrlList.GABPAYMENTSLIST+`?page=${page}`, {}, 'GET', true);
        return response.data.data;
    } catch (error) {
        _sendResponse('error', error);
    }
};

export const getFabPaymentsList = async (page) => {
    try {

        let response = await apiCall(APIUrlList.FABPAYMENTSLIST+`?page=${page}`, {}, 'GET', true);
        return response.data.data;
    } catch (error) {
        _sendResponse('error', error);
    }
};

export const getOrderDetails = async (order_id) => {
    try {

        let response = await apiCall(APIUrlList.GETORDERDETAILS+`/${order_id}`, {}, 'GET', true);
        return response.data.data;
    } catch (error) {
        _sendResponse('error', error);
    }
};
