<template>
  <section>
    <b-modal
      id="modal-center"
      v-model="showModal"
      :no-close-on-backdrop="true"
      centered
      hide-footer
      size="md"
      content-class="border-0 rounded-0"
      body-class="pb-4"
      header-class="border-bottom-0 pt-5 pb-2"
      :title="type==='edit'? 'Edit Product' : 'Product in store'"
    >
      <loading-spinner v-if="loading" />
      <template v-slot:modal-header="">
        <span class="font-weight-bold h4 mb-0">
          {{ type==='edit'? 'Edit Product' : 'Product in store' }}
        </span>
        <b-button
          type="button"
          variant="default"
          class="p-0 ml-auto shadow-none"
          style="margin-top:-25px"
          @click="handleShow"
        >
          <icon-close
            height="24"
            width="24"
          />
        </b-button>
      </template>
      <b-row>
        <b-col md="6">
          <div class="font-open-sans mb-4">
            <div class="font-weight-600">
              Item
            </div>
            <div class="bank-search-input">
              <b-input-group class="mt-1">
                <b-form-input
                  id="item"
                  v-model="item"
                  v-validate="{required: true}"
                  :state="validateState('item')"
                  name="item"
                  type="text"
                  class="shadow-none"
                />
              </b-input-group>
              <span class="valdiation_error">{{ veeErrors.first("item") }}</span>
            </div>
          </div>
        </b-col>
        <b-col md="6">
          <div class="font-open-sans mb-4">
            <div class="font-weight-600">
              Price
            </div>
            <div class="bank-search-input">
              <b-input-group class="mt-1">
                <template #prepend>
                  <b-input-group-text class="bg-transparent border-right-0 h-100">
                    $
                  </b-input-group-text>
                </template>
                <b-form-input
                  id="price"
                  v-model="price"
                  v-validate="{required: true, 'decimal':true, 'price':true}"
                  :state="validateState('price')"
                  name="price"
                  type="text"
                  class="shadow-none"
                />
              </b-input-group>
              <span class="valdiation_error">{{ veeErrors.first("price") }}</span>
            </div>
          </div>
        </b-col>
        <b-col
          md="12"
          align-self="stretch"
        >
          <div class="font-open-sans mb-4">
            <div class="d-flex">
              <div class="font-weight-600">
                Photos
              </div>
              <input
                :id="`pic`"
                ref="product_pic"
                v-validate="{required: images.length > 0 ? false : true}"
                type="file"
                name="Photos"
                :state="validateState('Photos')"
                multiple
                class="mr-2"
                style="display:none"
                accept="image/png, image/jpeg, image/jpg, video/*"
                @change="handleProductImages"
              >
              <label
                :for="`pic`"
                class="ml-2"
              ><b-button
                pill
                variant="primary"
                class="py-0"
                @click="handleFile"
              >Browse</b-button></label>
            </div>
            <small class="font-weight-light mb-0"> Product photo size must be 800x800 and over.</small>
            <div
              :class="[
                style['product-photos-enable'],
                'bank-search-input py-3 pl-1',
              ]"
            >
              <p>Upload upto 5 photos and 1 video ({{ (6 - images.length) }} left)</p>
              <div class="d-flex">
                <div 
                  v-if="videoList"
                  :class="[style['image-container']]" 
                >
                  <video 
                    fluid 
                    class="product-video"
                  >
                    <source 
                      id="product-video" 
                      :src="videoList.p_image"
                    >
                  </video>
                  <div class="play" />
                  <button
                    :class="[style['top-right'], style['edit-top-right']]"
                    @click="removeImage(videoList, 'video')"
                  >
                    <img :src="closeIcon">
                  </button>
                </div>
                <div
                  v-for="(image,index) in images"
                  :key="`${index}`"
                  :class="[style['image-container']]"
                >
                  <!-- <img
                    v-if="image.type === 'image'"
                    :id="`${index}`"
                    :src="image.image"
                    :class="[style['edit-image'], 'ml-3']"
                  > -->
                  <div
                    v-if="image.type === 'image'"
                    :id="`${index}`"
                    :class="[style['edit-image'], 'ml-3']"
                    :style="{backgroundImage: `url(${image.image})`}"
                  />
                  <button
                    v-if="image.type === 'image'"
                    :class="[style['top-right'], style['edit-top-right']]"
                    @click="removeImage(image, 'image')"
                  >
                    <img :src="closeIcon">
                  </button>
                </div>
              </div>
            </div>
            <span class="valdiation_error">{{ veeErrors.first("Photos") }}</span>
          </div>
        </b-col>
        <b-col md="12">
          <div class="font-open-sans mb-4">
            <div class="font-weight-600">
              Description
            </div>
            <div class="bank-search-input">
              <b-input-group class="mt-1">
                <b-form-textarea
                  id="description"
                  v-model="description"
                  v-validate="{required: true}"
                  :state="validateState('description')"
                  name="description"
                  type="textarea"
                  rows="5"
                />
              </b-input-group>
              <span class="valdiation_error">{{ veeErrors.first("description") }}</span>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <div class="w-100 text-right">
          <b-row>
            <b-col>
              <button
                class="btn mr-3 mt-3 text-uppercase"
                @click="handleShow"
              >
                Cancel
              </button>
              <b-button
                variant="primary"
                class="px-3 mr-3 mt-3"
                pill
                @click="onSubmit"
              >
                <span class="px-4 text-uppercase">Save</span>
              </b-button>
            </b-col>
          </b-row>
        </div>
      </b-row>
    </b-modal>
  </section>
</template>

<script>
import IconClose from "../../assets/svg-import/close.svg";
import style from '../../styles/settings.module.scss';
import { uploadToBucket, removeFromBucket } from '../../js/common-function';
import { addEditProduct } from '../../api/settings';
import LoadingSpinner from '../Common/LoadingSpinner.vue';
import closeIcon from '../../assets/images/imgpsh_fullsize_anim.png';
import { Validator } from 'vee-validate';
import { _sendResponse } from '../../js/common-function';
import { IMAGE_URL } from '../../config/config';
Validator.extend('price', {
    getMessage: "Must be a valid price.",
    validate: value => (/^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/).test(value)
});
export default {
    name: "AddProduct",
    components: { IconClose,LoadingSpinner },
    props:{
        showModal: {
            type: Boolean,
            default: false
        },
        productData:{
            type:[Object, PointerEvent],
            default: ()=>{}
        },
        type:{
            type:String,
            default:""
        },
        userId:{
            type: String,
            default:"",
        }
    },
    data() {
        return {
            style,
            images: [],
            imageToPass:[],
            loading: false,
            description: "",
            item:"",
            price:"",
            productId:"",
            closeIcon,
            productType:'image',
            videoList:null, 
        };
    },
    watch:{
        productData:function(newVal){
            this.item = "";
            this.description = "";
            this.price = "";
            this.images = [];
            this.imageToPass = [];
            this.productId = ""; 
            if (newVal && newVal.item){
                this.item = newVal.item;
                this.description = newVal.description;
                this.price = newVal.price;
                this.productId = newVal.id;
                this.videoList = newVal.video;
                newVal.images.map(value => {
                    let objToPush = {
                        type:value.file_type,
                        image: value.p_image
                    };
                    let nameToPass = value.p_image.split(IMAGE_URL)[1];
                    this.images.push(objToPush);
                    this.imageToPass.push({file_type:value.file_type,name:nameToPass});
                });
            } 
        }
    },
    methods:{
        validateState(ref) {
            if (
                this.veeFields[ref] &&
                (this.veeFields[ref].dirty || this.veeFields[ref].validated)
            ) {
                return !this.veeErrors.has(ref);
            }
            return null;
        },
        async handleProductImages(e) {
            let me = this;
            let totalLength = this.images.length + e.target.files.length;
            if (totalLength <= 6) {
                for (var i = 0; i < e.target.files.length; i++) {
                    if (e.target.files[i].type === "image/jpeg" || e.target.files[i].type === "image/png" || e.target.files[i].type === "image/jng" || e.target.files[i].type.includes('video')){
                        me.loading = true;
                        let imageMain = e.target.files[i];
                        if (imageMain.type.includes('video')){
                            if (this.videoList !== null){
                                _sendResponse('error', 'You cannot upload more than one video');
                                this.loading = false;
                            } else {
                                me.productType = 'video';
                                if (e.target.files[i].size < 10000000){
                                    var videoFile = URL.createObjectURL(e.target.files[i]); 
                                    me.images.push({image:videoFile, type:this.productType});
                                    let nameToPass = await uploadToBucket(imageMain,"uploads/products/");
                                    if (nameToPass){
                                        let objToPass = {
                                            file_type: me.productType,
                                            name: nameToPass
                                        };
                                        this.videoList = {
                                            p_image: videoFile,
                                            image: nameToPass,
                                            name: nameToPass
                                        };
                                        me.imageToPass.push(objToPass);
                                        me.loading = false;
                                    } else {
                                        me.loading = false;
                                    }
                                } else {
                                    me.loading = false;
                                    _sendResponse('error', 'You cannot upload video of size more than 10MB.');
                                }
                            } 
                        } else {
                            let totalLength = me.confirmFiveImages().length + e.target.files.length;
                            if (totalLength > 5){
                                _sendResponse('error', 'Cannot Upload more than 5 images');
                                me.loading = false;
                            } else {
                                me.productType = 'image';
                                var image = new Image();
                                image.src = URL.createObjectURL(e.target.files[i]);
                                image.onload = async function(){
                                    if (this.width>800 && this.height > 800 ){
                                        me.images.push({image:URL.createObjectURL(imageMain),type:me.productType});
                                        let nameToPass = await uploadToBucket(imageMain,"uploads/products/");
                                        var objToPass = {};
                                        if (nameToPass){
                                            objToPass = {
                                                file_type:me.productType,
                                                name:nameToPass
                                            };
                                            me.loading = false;
                                        }
                                        me.imageToPass.push(objToPass);
                                    } else {
                                        _sendResponse('error', 'Ideal image size should be 800*800');
                                        me.loading = false;
                                    }
                                };
                            }
                        }
                    } else {
                        me.loading = false;
                    }
                }
            } else {
                this.loading = false;
                _sendResponse('error', "You cannot upload more than 5 images");
            }
        },
        confirmOnlyOneVideo(){
            return this.images.filter(value => {return value.type === 'video' ? true : false;});
        },
        confirmFiveImages(){
            return this.images.filter(value =>{return value.type === 'image' ? true : false; });
        },
        async removeImage(image, flag){
            this.loading = true;
            if (flag === 'image'){
                this.images.map(async (value,index) => {
                    if (image.image === value.image){
                        this.images.splice(index, 1);

                        let imageToRemove = "";
                        if (this.imageToPass.length > 1){
                            imageToRemove = this.imageToPass.splice(index, 1);
                        } else {
                            imageToRemove = this.imageToPass;
                            this.imageToPass = [];
                        }
                        let deletedImage = await removeFromBucket(imageToRemove[0].name ? imageToRemove[0].name : imageToRemove[0]);
                        if (deletedImage){
                            this.loading = false;
                        }
                    }
                });
            } else {
                let me = this;
                let deletedImage = await removeFromBucket(image.image);
                this.imageToPass.findIndex(function(current, index){
                    if (current && current.name === image.image){
                        me.imageToPass.splice(index, 1);
                    }                    
                });
                if (deletedImage){
                    this.loading = false;
                    this.videoList = null;
                }
                
            }
        },
        async onSubmit(){
            this.loading = true;
            this.$validator.validateAll().then(async result => {
                if (!result){
                    this.loading = false;
                    return;
                }
                let formData = {
                    name: this.item,
                    price: this.price,
                    description: this.description,
                    product_images: this.imageToPass, 
                };
                this.productId !== "" ? formData.product_id = this.productId : null;
                let addProduct = await addEditProduct(formData);
                if (addProduct){
                    this.$emit('handleShow', false);
                    this.loading = false;
                    setTimeout(()=> {
                        window.location.href = window.location.pathname === '/settings' ? `/settings` : `/products/${window.btoa(this.userId)}`;
                    },2000);
                }
            });
        },
        handleShow(){
            this.$emit('handleShow', false);
        },
        handleFile(){
            document.getElementById('pic').click();
        },
    }
};
</script>
